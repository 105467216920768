<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <b-form @submit.prevent="sendForgotPasswordLink" novalidate>
                  <h1>{{ $t("app.login.forgotpassword.reset") }}</h1>
                  <div
                    v-if="sendMailErrorMessage != ''"
                    class="mandatory-color error-font"
                  >{{ sendMailErrorMessage }}</div>
                  <CInput
                    :placeholder="$t('app.login.forgot.password.placeholder')"
                    autocomplete="username email"
                    required
                    was-validated
                    v-model.trim="forgotpasswordEmail"
                  >
                    <template #prepend-content>
                      <CIcon name="cil-user" />
                    </template>
                  </CInput>
                  <show-errors
                    class="txt-left mb-4"
                    :control="$v.forgotpasswordEmail"
                    :field="$t('app.forgotpassword.email')"
                  />
                  <CRow class="mb-2">
                    <CCol col="6" lg="6" md="6" xl="6" sm="6" class="text-left">
                      <vue-recaptcha
                        ref="recaptcha"
                        :sitekey="siteKey"
                        @verify="verifyMethod"
                        @expired="expiredMethod"
                        @render="renderMethod"
                        @error="errorMethod"
                        size="nromal"
                      ></vue-recaptcha>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol col="6" lg="6" md="6" xl="6" sm="6" class="text-left">
                      <CButton
                        color="primary"
                        class="px-2"
                        @click="sendForgotPasswordLink"
                        :disabled="!isCaptchaValidated"
                      >{{ $t("app.forgotpassword.btn.send") }}</CButton>
                    </CCol>
                    <CCol col="6" lg="6" md="6" xl="6" sm="6" class="text-right">
                      <router-link to="/login">
                        <CButton color="link" class="px-0">
                          {{
                          $t("app.return.login")
                          }}
                        </CButton>
                      </router-link>
                    </CCol>
                  </CRow>
                </b-form>

                <b-modal
                  id="bv-modal-example"
                  size="md"
                  centered
                  hide-footer
                  @hidden="closeSuccessModal"
                >
                  <template style="margin-left: auto">
                    {{
                    $t("app.login.forgotpassword.reset")
                    }}
                  </template>

                  <div class="mb-4">
                    <h6 class="text-left text-md-center mt-3 mb-3">
                      <strong>{{ $t("app.forgotpassword.link.sent") }}</strong>
                    </h6>
                    <router-link to="/login">
                      <CButton color="link" class="px-0 pull-right">
                        {{
                        $t("app.return.login")
                        }}
                      </CButton>
                    </router-link>
                  </div>
                </b-modal>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import { VueRecaptcha } from "vue-recaptcha";
import { required, maxLength } from "vuelidate/lib/validators";
import ShowErrors from "@/components/ShowErrors/ShowErrors.vue";
import { emailValidation } from "@/shared/utils";
import _ from "lodash";
import { REQUEST_RESET_PASSWORD } from "@/store/actions.type";
export default {
  name: "ForgotPwd",
  components: { ShowErrors, VueRecaptcha },
  data() {
    return {
      siteKey: process.env.VUE_APP_SITE_KEY,
      sendMailErrorMessage: "",
      forgotpasswordEmail: "",
      linkSent: false,
      disabled: false,
      isCaptchaValidated: false
    };
  },
  validations: {
    forgotpasswordEmail: {
      required,
      emailValidation
    }
  },
  methods: {
    verifyMethod(response) {
      this.isCaptchaValidated = true;
    },
    expiredMethod(x) {
      this.isCaptchaValidated = false;
      this.$refs.recaptcha.reset();
    },
    renderMethod() {
      this.$refs.recaptcha.reset();
    },
    errorMethod() {},
    /**
     * @method sendForgotPasswordLink
     * @description It will send mail for forgot password
     * @author @Jeevan Poojary
     */
    async sendForgotPasswordLink() {
      this.linkSent = true;
      /* If valid it will navigate to success screen */
      this.$v.$touch();
      if (!this.$v.forgotpasswordEmail.$invalid) {
        this.disabled = true;
        this.$store
          .dispatch(REQUEST_RESET_PASSWORD, {
            email: this.forgotpasswordEmail,
            type: "admin"
          })
          .then(data => {
            if (_.get(data, "success", true)) {
              this.linkSent = true;
              this.sendMailErrorMessage = "";
              this.$bvModal.show("bv-modal-example");
            } else {
              this.sendMailErrorMessage = _.get(data, "message", "Error");
            }
          });
      }
    }
  }
};
</script>
<style></style>
